import React, { Component } from "react"
import { navigate } from "gatsby"
import Utils from "../../utils/node.utils"
import { store as tStore } from "../Store/Store"
import StaticPage from "../StaticPage/StaticPage"
import Loading from "../Loading/Loading"
import axios from "axios"

const maxRetries = 10

export default class Order extends Component {
  state = {
    order: {
      status: "",
    },
    retryCnt: 0,
  }

  componentDidMount() {
    const { language } = this.props

    const order = JSON.parse(localStorage.getItem("ss_o"))
    if (!order) {
      navigate(Utils.GetLanguageLink(language, "/"))
      return
    }

    this.unsubscribe = tStore.subscribe(this.storeUpdate)
    this.setState(
      {
        order: order,
      },
      this.storeUpdate
    )
  }

  componentWillUnmount() {
    Utils.CancelToken(this.cancelToken)
    Utils.Unsubscribe(this.unsubscribe)
  }

  storeUpdate = () => {
    const { t } = this.state
    const storeState = tStore.getState()

    if (storeState.t && storeState.t !== t) {
      this.setState(
        {
          t: storeState.t,
        },
        this.getOrder
      )
    }
  }

  setOrder = order => {
    const { retryCnt } = this.state
    this.loading = false
    this.loaded = true
    this.setState(
      {
        order: {
          id: order.id,
          cid: order.client_id,
          checkout_link: order.checkout_link,
          status: order.status,
        },
        retryCnt: retryCnt + 1,
      },
      this.retry
    )
  }

  getOrder = () => {
    const { t, order } = this.state
    const { location } = this.props

    if (!t) {
      console.warn("Cannot read order, not authenticated")
      return
    }

    if (this.loaded) {
      return
    }

    if (this.loading) {
      return
    }
    this.loading = true

    const urlParams = new URLSearchParams(location.search)
    const paypalPaymentID = urlParams.get("paymentId")
    const paypalPayerID = urlParams.get("PayerID")
    const paypalToken = urlParams.get("token")
    const id = order.id

    if (paypalToken) {
      if (paypalPaymentID && paypalPayerID) {
        // Paypal execute request
        axios
          .post("/enjocommerce/v1/orders/external/paypal", {
            resource: { parent_payment: paypalPaymentID },
            payer_id: paypalPayerID,
          })
          .then(() => {
            this.cancelToken = Utils.GetOrder(id, t, this.setOrder, true)
          })
          .catch(err => {
            if (Utils.LogAxiosError(err)) {
              return
            }
            this.loaded = false
            if (err.response) {
              if (err.response.status == 404) {
                localStorage.removeItem("ss_o")
                localStorage.removeItem("cart-order")
              }
            }
            const existingCart = Utils.GetCart("cart-order")
            if (existingCart) {
              localStorage.setItem("cart-items", JSON.stringify(existingCart))
              localStorage.removeItem("cart-order")
            }
          })
      } else {
        if (localStorage.getItem("ss_o_cancel")) {
          // Canceled twice, payment is more than likely abandoned
          localStorage.removeItem("ss_o")
          localStorage.removeItem("ss_o_cancel")
          const existingCart = Utils.GetCart("cart-order")
          if (existingCart) {
            localStorage.setItem("cart-items", JSON.stringify(existingCart))
            localStorage.removeItem("cart-order")
          }
        } else {
          // Flag as being canceled once
          localStorage.setItem("ss_o_cancel", true)
        }
        let stateOrder = this.state.order
        stateOrder.status = "failed"
        this.setOrder(stateOrder)
      }
    } else {
      this.cancelToken = Utils.GetOrder(id, t, this.setOrder, true)
    }
  }

  retry = () => {
    const { retryCnt } = this.state
    if (this.loaded) {
      return
    }

    if (retryCnt < maxRetries) {
      this.getOrder()
    }
  }

  getOrderID = () => {
    const { order } = this.state
    if (order.id !== "") {
      return "Order ID #" + order.id
    }
    return ""
  }

  empty = () => {
    return ""
  }

  statusIsPaid() {
    const { order } = this.state
    return order.status === "paid"
  }

  statusIsPending() {
    const { order } = this.state
    return (
      !order.status ||
      order.status == "" ||
      order.status === "open" ||
      order.status === "error"
    )
  }

  statusIsFailed() {
    const { order } = this.state
    return (
      order.status === "canceled" ||
      order.status === "expired" ||
      order.status === "failed"
    )
  }

  statusCouldNotBeFetched() {
    const { order } = this.state
    return order.status
  }

  statusIsError() {
    const { order } = this.state
    return order.status === "error" || order.status === "error_perm"
  }

  getOverlay = () => {
    const {
      createOverlay,
      createFailedOverlay,
      createErrorOverlay,
    } = this.props

    if (this.statusIsPending()) {
      return this.getLoading
    }

    if (this.statusIsPaid()) {
      return createOverlay
    }
    if (this.statusIsFailed()) {
      return createFailedOverlay
    }
    if (this.statusIsError()) {
      return createErrorOverlay
    }
    return this.empty
  }

  getLoading = () => {
    return <Loading />
  }

  getContentList = () => {
    const {
      createContentList,
      createErrorContentList,
      createFailedContentList,
      createEmptyContentList,
    } = this.props

    if (this.statusIsPaid()) {
      return createContentList
    }
    if (this.statusIsFailed()) {
      return createFailedContentList
    }
    if (this.statusIsError()) {
      return createErrorContentList
    }
    return createEmptyContentList
  }

  render() {
    const {
      commonContentMap,
      contentMap,
      language,
      originPage,
      location,
      title,
      description,
      breadCrumbs,
      img,
      keywords,
    } = this.props

    return (
      <StaticPage
        img={img}
        language={language}
        originPage={originPage}
        location={location}
        contentMap={contentMap}
        commonContentMap={commonContentMap}
        title={title}
        description={description}
        breadCrumbs={breadCrumbs}
        createContentFun={this.getContentList()}
        createOverlayFun={this.getOverlay()}
        keywords={keywords}
      />
    )
  }
}
